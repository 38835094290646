.Pdfviewer {
  position: relative;
}

.Pdfviewer canvas {
  max-width: 100%;
  height: auto !important;
}

.Pdfviewer-controls {
  padding: 0;
  background: #239447;
}

.Pdfviewer-controls button {
  display: inline-block;
  border: 0px none;
  border-radius: 0;
  background: #239447;
  color: #99ffb9;
  padding: 3px 15px 6px 15px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.Pdfviewer-controls button:hover {
  color: #fff;
}

.Pdfviewer-controls button.disabled {
  color: #eee;
}

.navbar .nav-items__item > button {
  display: block;
  color: #99ffb9;
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
}

button.collapse-header {
  padding: 0.5rem 0;
  display: block;
  position: relative;
  border-bottom: 0;
  border: none;
  background: transparent;
  border-top: 2px solid #efeeee;
  width: 100%;
  text-align: left;
}

button.collapse-header .content-heading,
button.collapse-header .filter-heading,
button.collapse-header .content-heading--single {
  margin-bottom: 0;
  width: 80%;
}

button.collapse-header .icon {
  color: #29ae54;
  font-size: 1.35rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media (min-width: 768px) {
  .navbar .nav-items__item > button {
    padding: 1rem 0;
  }
}

.navbar__secondary-nav {
  float: right;
}

.ais-ClearRefinements {
  position: relative;
  z-index: 100;
  font-size: 0.6rem;
  padding: 0.5rem;
  text-align: right;
  color: #d9534f !important;
}

.suggestion-container {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.suggestion-container:hover {
  background: #eee;
}

.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: 0.3rem;
}

.ais-Pagination-link {
  padding: 0.3rem 0.6rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.ais-Pagination-link:hover,
.ais-Pagination-link:focus {
  background-color: #e3e5ec;
}
.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: 0.6;
  cursor: not-allowed;
  color: #239447;
}
.ais-Pagination-item--disabled .ais-Pagination-link:hover,
.ais-Pagination-item--disabled .ais-Pagination-link:focus {
  color: #239447;
  background-color: #fff;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #239447;
  border-color: #239447;
}
.ais-Pagination-item--selected .ais-Pagination-link:hover,
.ais-Pagination-item--selected .ais-Pagination-link:focus {
  color: #fff;
}

.navbar .navbar-logo .logo--text {
  display: inherit;
}

@media (max-width: 767px) {
  .navbar .navbar-logo .logo--text {
    padding-left: 15px;
  }
}

.navbar__main-nav .nav-items .nav-items__item > a {
  padding: 0.5rem 1rem;
}

.navbar .nav-items__item {
  font-size: 0.9rem;
}

.navbar .nav-items__item {
  font-size: 0.9rem;
  transition: 0.3s;
  font-weight: 300;
  position: relative;
}

.navbar .nav-items__item .nav-link {
  display: block;
  color: #99ffb9;
}

.navbar .nav-items__item .nav-link:hover {
  color: #fff;
  cursor: pointer;
}

.navbar__main-nav .nav-items .nav-items__item > button,
.navbar__main-nav .nav-items .nav-items__item > span.nav-link {
  padding: 0.5rem 1rem;
}
.navbar .nav-items__item span.nav-link {
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .navbar__main-nav .nav-items .nav-items__item > button,
  .navbar__main-nav .nav-items .nav-items__item > a,
  .navbar__main-nav .nav-items .nav-items__item > span.nav-link {
    padding: 1.6rem 0;
  }
}

.navbar .navbar-logo .logo--text {
  max-height: 45px;
  width: 10rem;
}

.site-footer__logo .logo-footer__img {
  max-width: 150px;
  margin-top: 10px;
}

button.collapse-header {
  border-width: 1px !important;
}

.block-article__text {
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
